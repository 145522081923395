import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigItem } from '../model/configitem';
import { LogService } from './log.service';
import { mmapi } from './mmapi';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient, private log: LogService) { }

  public loadAllConfigs(): Observable<ConfigItem[]> {
    this.log.log('reading all configs from API');
    return this.http.get<ConfigItem[]>(environment.apiMediManagerURL + mmapi.CONFIG_GET);
  }

  public create(key: string, value: string): Observable<ConfigItem> {
    this.log.log('create config on API');
    const cfg = new ConfigItem(-1, key, value);
    let url = mmapi.CONFIG_CREATE;
    return this.http.post<ConfigItem>(environment.apiMediManagerURL + url, cfg);
  }

  public update(config: ConfigItem): Observable<ConfigItem> {
    this.log.log('updating config on API');
    let url = mmapi.CONFIG_UPDATE;
    return this.http.post<ConfigItem>(environment.apiMediManagerURL + url, config);
  }

  public delete(id: number): Observable<any> {
    this.log.log('deleting config ${id} on API');
    return this.http.post<any>(environment.apiMediManagerURL + mmapi.CONFIG_DELETE, id);
  }
}
