import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorMessage } from './ui/error/error-message';
import { AuthService, LoginState } from './svc/auth.service';
import { MatChipsModule } from '@angular/material/chips';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MediManagerUI';

  username: string;
  loginState : LoginState;

  usernameSubscription: Subscription;
  loginStateSubscription: Subscription;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    ){
    this.matIconRegistry.addSvgIcon(
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/plus.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'apply',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/checked.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'remove',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/unchecked.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'undo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/undo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'refill',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/refill.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'drug',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/drug.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'prognosis',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/prognosis.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'config',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/config.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'externalLink',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icon/external-link.svg')
    );

    this.usernameSubscription = this.authService.usernameChange.subscribe((value) => {
      this.username= value;
    });
    this.username = this.authService.getUsername();

    this.loginStateSubscription = this.authService.loginStateChange.subscribe((value) => {
      this.loginState = value;
    });
  }

  canActivate(): boolean {
    return this.authService.isLoggedIn(); 
  }

}
