<app-error-message *ngIf="errorMessage" [message]="errorMessage" [autohide]="true"></app-error-message>

<mat-card appearance="outlined" *ngIf="!drugs">
    <app-spinner size="small" text="drugs loading"></app-spinner>
</mat-card>

<mat-accordion>
    <mat-expansion-panel *ngFor="let drug of drugs">
        <mat-expansion-panel-header class="right-aligned-header">
            <mat-panel-title>
                {{drug.name}} {{drug.doseMg}}mg
            </mat-panel-title>
            <mat-panel-description>
                <div style="float:left;"><a *ngIf="drug.url" href="{{drug.url}}" target="_blank"><mat-icon svgIcon="externalLink" matTooltip="{{drug.url}}"></mat-icon></a></div>
                <div style="float:left; width:60px; text-align: right;">{{drug.stock}}</div>
                <div style="float:left; width:60px; text-align: left; margin-left: 2px;">in stock</div>
            </mat-panel-description>
        </mat-expansion-panel-header>
            <app-drug-detail [drug]="drug" [editable]="true"></app-drug-detail>
            
        <mat-action-row>
            <button mat-stroked-button (click)="deleteDrug(drug)">
                <mat-icon svgIcon="delete"></mat-icon><span class="button-text">Delete Drug</span>
            </button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>

<div style="height: 5px;">&nbsp;</div>
<button mat-stroked-button (click)="createDrug()">
    <mat-icon svgIcon="plus"></mat-icon><span class="button-text">New</span>
</button>

