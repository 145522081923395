import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { withInterceptors, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { MedimanagerRoutingModule } from './medimanager/medimanager-routing.module';
import { DrugsComponent } from './ui/drugs/drugs.component';
import { DrugDetailComponent } from './ui/drugs/drug-detail/drug-detail.component';
import { StockRefillComponent } from './ui/stock-refill/stock-refill.component';
import { EdittextComponent } from './ui/edittext/edittext.component';
import { ErrorMessageComponent } from './ui/error/error-message.component';
import { ConfigComponent } from './ui/config/config.component';
import { ConfigeditableComponent } from './ui/config/config-editable/configeditable.component';
import { SpinnerComponent } from './ui/spinner/spinner.component';
import { PrognosisComponent } from './ui/prognosis/prognosis.component';
import { JwtInterceptor } from './svc/jwtinterceptor';
import { LoginComponent } from './ui/login/login.component';
import { MatChipsModule } from '@angular/material/chips';


@NgModule({ declarations: [
        AppComponent,
        DrugsComponent,
        DrugDetailComponent,
        StockRefillComponent,
        EdittextComponent,
        ErrorMessageComponent,
        ConfigComponent,
        ConfigeditableComponent,
        SpinnerComponent,
        PrognosisComponent,
        LoginComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MedimanagerRoutingModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatDividerModule,
        MatCardModule,
        MatSortModule,
        MatTooltipModule,
        MatCheckboxModule], providers: [DatePipe,
        provideHttpClient(withInterceptors([
            JwtInterceptor
        ])), provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
}
