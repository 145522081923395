import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { StockRefillData } from './stockrefilldata';

@Component({
    selector: 'app-stock-refill',
    templateUrl: './stock-refill.component.html',
    styleUrls: ['./stock-refill.component.css']
})
export class StockRefillComponent {

    constructor(
        public dialogRef: MatDialogRef<StockRefillComponent>,
        @Inject(MAT_DIALOG_DATA) public data: StockRefillData) { }

    cancelRefill(): void {
        this.data.amount=0;
        this.dialogRef.close();
    }

}
