import { Component, Input, Output, OnChanges, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigItem } from 'src/app/model/configitem';
import { ConfigService } from 'src/app/svc/config.service';

@Component({
  selector: 'app-configeditable',
  templateUrl: './configeditable.component.html',
  styleUrls: ['./configeditable.component.css']
})
export class ConfigeditableComponent implements OnChanges {

  @Input()
  config: ConfigItem;
  
  @Output()
  saveConfigItemEvent = new EventEmitter<ConfigItem>();  
    
  configForm: UntypedFormGroup;

  constructor(private configService: ConfigService, private formBuilder: UntypedFormBuilder) { }

  ngOnChanges(): void {
    if (this.config && !this.configForm) {      
      this.configForm = this.formBuilder.group({
        configkey: ['', [Validators.required, Validators.pattern('[0-9a-zA-Z\.-]*')]],
        configvalue: ['']
        });

        this.setFormValues();
      }
  }

  setFormValues(): void {
    this.configForm.controls.configkey.setValue(this.config.key);
    this.configForm.controls.configvalue.setValue(this.config.value); 
  }

  resetFormValues(): void {
    this.config.key = this.configForm.controls.configkey.value;
    this.config.value = this.configForm.controls.configvalue.value;
  }

  onSaveClick(): void {
    this.resetFormValues();
    this.saveConfigItemEvent.next(this.config);
  }

  onCancelClick(): void {
    this.setFormValues();
  }

  isCancelActive(): boolean {
   return (this.config.key !== this.configForm.controls.configkey.value || this.config.value !== this.configForm.controls.configvalue.value);
  }

  isSaveActive(): boolean {
    return this.isCancelActive();
  }

}
