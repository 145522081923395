<app-error-message *ngIf="errorMessage" [message]="errorMessage" [autohide]="true"></app-error-message>

<mat-card class="card" *ngIf="!isLoggedIn()">
    <strong>Login</strong>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
            <label class="form-label">Username</label>
            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
        </div>
        <div>
            <label class="form-label">Password</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>
        <div>
            <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                Login
            </button>
        </div>
    </form>
</mat-card>
<mat-card class="card" *ngIf="isLoggedIn()">
    <button mat-stroked-button (click)="logout()">logout</button><br/>
</mat-card>