import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { LogService } from './log.service';
import { mmapi } from './mmapi';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';

export enum LoginState {
    NOTLOGGED = 0,
    LOGGED = 1
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    
    private username: string;

    usernameChange: Subject<string> = new Subject<string>();
    loginStateChange : Subject<LoginState> = new Subject<LoginState>();

    constructor(private http: HttpClient, private log: LogService) {
        let token = this.getRefreshToken();
        if (token) {
            this.updateUsernameFromToken();
        }
    }
    
    login(username: string, password: string): Observable<any> {
        this.log.log('login to backend in order to obtain tokens');
        var payload = {
            'user': username,
            'password': password,
        };
        const serverRequest = this.http.post<string>(environment.apiMediManagerURL + mmapi.LOGIN, payload);
        const observable = new Observable(
            (observer) => {
                // first get the login request from the server
                serverRequest.subscribe({
                        next: data => {
                            this.storeTokens(data['refresh'], data['access']);
                            this.emitLoginState(LoginState.LOGGED);

                            this.updateUsernameFromToken();
                            this.emitUsernameChange();

                            observer.next("login successful");
                        },
                        error: error => {
                            this.log.log(error);
                            this.clearTokens();
                            observer.error(error.error["message"]);
                        }
                    }
                    );
                });
        return observable;
    }

    logout(): Observable<any> {
        this.log.log('logout from backend');
        
        const serverRequest = this.http.post<string>(environment.apiMediManagerURL + mmapi.LOGOUT, new HttpParams());
        const observable = new Observable(
            (observer) => {
                // first get the login request from the server
                serverRequest.subscribe({
                        next: data => {
                            this.clearTokens();
                            this.emitLoginState(LoginState.NOTLOGGED);
                            this.username = "";
                            this.emitUsernameChange();
                            observer.next("logout successful");
                        },
                        error: error => {
                            this.log.log(error);
                            // if the token is expired, clear token directly
                            if (error.error['business_code']=='TOKEN_EXPIRED') {
                                this.clearTokens();
                                observer.error("your refreshToken was expired - you got logged out automatically");
                            } else {
                                observer.error(error.error["message"]);
                            }
                        }
                    }
                    );
                });
        return observable;
    }

    updateUsernameFromToken() {
        let payload = JSON.parse(atob(this.getRefreshToken().split('.')[1]));
        this.username = payload['user'];
    }

    getUsername(): string {
        return this.username;
    }

    emitLoginState(state : LoginState) {
        this.loginStateChange.next(state);
    }

    emitUsernameChange() {
        this.usernameChange.next(this.username);
    }

    refreshAccessToken(): Observable<Object> {
        this.log.log('refreshing JWT token');
        return this.http.get<string>(environment.apiMediManagerURL + mmapi.REFRESH);
    }

    storeAccessToken(accessToken: string) {
        localStorage.setItem('access_token', accessToken);
    }

    storeRefreshToken(refreshToken: string) {
        localStorage.setItem('refresh_token', refreshToken);
    }

    getAccessToken(): string {
        return localStorage.getItem('access_token');
    }

    getRefreshToken(): string {
        return localStorage.getItem('refresh_token');
    }

    storeTokens(refreshToken: string, accessToken: string) {
        this.storeRefreshToken(refreshToken);
        this.storeAccessToken(accessToken);
    }

    isLoginRoute(request: HttpRequest<any>): boolean {
        if (request.url == environment.apiMediManagerURL + mmapi.LOGIN) {
            return true;
        }
        return false;
    }

    isLogoutRoute(request: HttpRequest<any>): boolean {
        if (request.url == environment.apiMediManagerURL + mmapi.LOGOUT) {
            return true;
        }
        return false;
    }

    getToken(request: HttpRequest<any>): string|null {
        let token = this.getAccessToken();
        if (request.url == environment.apiMediManagerURL + mmapi.REFRESH)
        {
            this.log.log('token refresh requested');
            token = this.getRefreshToken();
        }  
        else if (request.url == environment.apiMediManagerURL + mmapi.LOGOUT)
        {
            this.log.log('logout requested');
            token = this.getRefreshToken();
        }
        else if (request.url == environment.apiMediManagerURL + mmapi.LOGIN)
        {
            this.log.log('login page');
            token = null;
        }
        return token;
    }

    clearTokens(): void {
      this.storeTokens("", "");
    }

    isLoggedIn(): boolean {
        var refreshToken = this.getRefreshToken();
        if (refreshToken) {
            return true;
        }
        return false;
    }
}
