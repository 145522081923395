import { Component, OnInit } from '@angular/core';
import { ConfigItem } from 'src/app/model/configitem';
import { ConfigService } from 'src/app/svc/config.service';
import { LogService } from 'src/app/svc/log.service';
import { ErrorMessage } from '../error/error-message';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {

  configs: ConfigItem[];
  errorMessage: ErrorMessage;

  constructor(private configService: ConfigService, private log: LogService) { }

  ngOnInit(): void {
    this.loadAllConfig();
  }

  loadAllConfig(): void {
    this.configService.loadAllConfigs().subscribe(
      configs => {
        this.configs = configs;
      }
    );
  }

  createNewConfigItem(): void {
    const keyVal = `replace.me.${Date.now().toString()}`;
    this.configService.create(keyVal, keyVal).subscribe(
      config => {
        this.ngOnInit();
      }
    );
  } 

  saveConfigItem(config: ConfigItem): void {
    this.configService.update(config).subscribe(
      {
        next: data => {
          this.log.log('saved config iteam {config.id}')
        },
        error: error => {
            this.errorMessage =  {description: 'cannot save config item', error: error.error};
            
        }
    }
    );
  }

  deleteConfigItem(item: ConfigItem): void {
    this.configService.delete(item.id).subscribe(
      {
        next: data => {
            // reload data
            this.ngOnInit();
        },
        error: error => {
            this.errorMessage =  {description: 'cannot delete config item', error: error.error};
            
        }
    }
    );
  }

}
