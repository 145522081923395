
<form [formGroup]="configForm" (ngSubmit)="onSaveClick()"*ngIf="config">
        <mat-card appearance="outlined" >
                <mat-form-field>
                        <mat-label>Id</mat-label>
                        <input matInput type="text" value="{{config.id}}" disabled="true">
                </mat-form-field>
                <br>
                <mat-form-field>
                        <mat-label>Key</mat-label>
                        <input matInput type="text" formControlName="configkey">
                </mat-form-field>
                <br>
                <mat-form-field>
                        <mat-label>Value</mat-label>
                        <input matInput type="text" formControlName="configvalue">
                </mat-form-field>
                <br>

                <button *ngIf="isSaveActive()" mat-stroked-button (click)="onSaveClick()">
                        <mat-icon svgIcon="apply"></mat-icon><span class="button-text">Apply</span>
                </button>
                <button *ngIf="isCancelActive()" mat-stroked-button (click)="onCancelClick()">
                        <mat-icon svgIcon="undo"></mat-icon><span class="button-text">Cancel</span>
                </button>
        </mat-card>
</form>