<app-error-message *ngIf="errorMessage" [message]="errorMessage" [autohide]="true"></app-error-message>

<mat-card appearance="outlined" *ngIf="!configs">
    <app-spinner size="small" text="configs loading"></app-spinner>
</mat-card>

<mat-accordion>
        <mat-expansion-panel *ngFor="let config of configs">
            <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title>
                    {{config.key}}
                </mat-panel-title>
                <mat-panel-description>
                        {{config.value}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <app-configeditable [config]="config" (saveConfigItemEvent)="saveConfigItem($event)"></app-configeditable>
               
            <mat-action-row>
                <button mat-stroked-button (click)="deleteConfigItem(config)">
                    <mat-icon svgIcon="delete"></mat-icon><span class="button-text">Delete Config</span>
                </button>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>

<button mat-stroked-button (click)="createNewConfigItem()">
        <mat-icon svgIcon="plus"></mat-icon><span class="button-text">New</span>
</button>
