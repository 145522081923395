import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Drug } from '../model/drug';
import { LogService } from './log.service';
import { mmapi } from './mmapi';
import { environment } from 'src/environments/environment';
import { Prognosis } from '../model/prognosis';

@Injectable({
    providedIn: 'root'
})
export class DrugService {

    constructor(private http: HttpClient, private log: LogService) {
    }

    dummy(id: number, name: string, doseMg: number, stock: number, url: string): Drug {
    	return new Drug(id, name, doseMg, doseMg, stock, "dummy prescription 1x of 2k", url, 0);
    }

    create(drug: Drug) {
        this.log.log('create new drug');
        this.http.post<Drug>(
            environment.apiMediManagerURL + mmapi.DRUG_CREATE,
            drug).subscribe({
                next: data => {
                    this.log.log(data);
                    drug.id = data.id;
                },
                error: error => {
                    this.log.log(error);
                }
            });
    }

    update(drug: Drug): Observable<Drug> {
        this.log.log('update drug');

        return this.http.post<any>(
            environment.apiMediManagerURL + mmapi.DRUG_UPDATE,
            drug);
    }

    delete(drug: Drug): Observable<any> {
        this.log.log('delete drug');
        const body = new HttpParams();
        return this.http.post<any>(
            environment.apiMediManagerURL + mmapi.DRUG_DELETE,
            drug.id);
    }

    get(): Observable<Drug[]> {
        this.log.log('reading all drugs from API');
        return this.http.get<Drug[]>(environment.apiMediManagerURL + mmapi.DRUG_GET);
    }

    refill(drug: Drug, amount: number): Observable<Drug> {
        this.log.log('refilling drug ' + drug.name + ' with ' + amount.toString());
        const d = new Drug(
            drug.id,
            drug.name, 
            drug.doseMg, 
            drug.doseMgPerDay,
            drug.stock + amount, 
            drug.prescription,
            drug.url,
            drug.inuse
        )
        return this.http.post<any>(
            environment.apiMediManagerURL + mmapi.DRUG_UPDATE,
            d);
    }

    prognosis(): Observable<Prognosis> {
        this.log.log('obtaining prognosis');
        return this.http.get<Prognosis>(environment.apiMediManagerURL + mmapi.DRUG_PROGNOSIS);
    }

    billOrRevert(amount: number): Observable<Prognosis> {
        this.log.log('charge stock');
        return this.http.post<Prognosis>(environment.apiMediManagerURL + mmapi.DRUG_CHARGE, amount);
    }
}
