<mat-card appearance="outlined" class="content">
        <mat-card-title class="title">Refill {{data.drug.name}} {{data.drug.doseMg}}mg</mat-card-title>
        <mat-form-field appearance="outline">
                <mat-label>Refill by #of pills (+/- allowed)</mat-label>
                <input matInput type="number" [(ngModel)]="data.amount">
        </mat-form-field>

        <div mat-dialog-actions>
                <button mat-stroked-button (click)="cancelRefill()">Cancel</button>
                <button mat-stroked-button [mat-dialog-close]="data.amount" cdkFocusInitial>Ok</button>
        </div>

        <mat-divider [inset]="true"></mat-divider>

        example package sizes:
        <table>
                <tr><td>42</td><td>Loratadine</td></tr>
                <tr><td>90</td><td>Jardiance</td></tr>
                <tr><td>98</td><td>Xarelto</td></tr>
                <tr><td>100</td><td>Aldactone</td></tr>
                <tr><td>168</td><td>Entresto</td></tr>
        </table>

</mat-card>
