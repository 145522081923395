<div class="topnavigation">
  <div class="header-link">
    <a *ngIf="!canActivate()" routerLink="login">
      <mat-chip>MediManager</mat-chip>
    </a>
  </div>

  <div *ngIf="canActivate()" class="header-link">
    <a routerLink="drugs">
      <mat-icon svgIcon="drug" matTooltip="Drug Management"></mat-icon>
    </a>
  </div>

  <div *ngIf="canActivate()" class="header-link">
    <a routerLink="prognosis">
      <mat-icon svgIcon="prognosis" matTooltip="Plan Prognosis"></mat-icon>
    </a>
  </div>

  <div *ngIf="canActivate()" class="header-link">
    <a routerLink="config">
      <mat-icon svgIcon="config" matTooltip="Configuration"></mat-icon>
    </a>
  </div>

  <div class="header-link">
    <a *ngIf="canActivate()" routerLink="login">
      <mat-chip *ngIf="username">{{username}}</mat-chip>
    </a>
  </div>

  <div class="header-finish"></div>
</div>
<div class="contentWithin"> 
<router-outlet></router-outlet>
</div>
