export class Drug {
    constructor(
        public id: number, 
        public name: string, 
        public doseMg: number, 
        public doseMgPerDay: number,
        public stock: number, 
        public prescription: string,
        public url: string,
        public inuse: number,
        ) {
    }
}
