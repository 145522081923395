import { Component, OnInit } from '@angular/core';
import { Drug } from '../../model/drug';
import { DrugService } from '../../svc/drug.service';
import { LogService } from '../../svc/log.service';
import { ErrorMessage } from '../error/error-message';

@Component({
    selector: 'app-drugs',
    templateUrl: './drugs.component.html',
    styleUrls: ['./drugs.component.css']
})
export class DrugsComponent implements OnInit {

    drugs: Drug[];
    selectedDrug: Drug;
    errorMessage: ErrorMessage;

    constructor(private drugService: DrugService, private log: LogService) { }

    ngOnInit() {
        this.getDrugs();
    }

    startEdit(drug: Drug): void {
        this.selectedDrug = drug;
    }

    stopEdit() {
        this.selectedDrug = null;
    }

    createDrug() {
        const d = this.drugService.dummy(-1, 'placeholder', 1, 0.0, null);
        this.drugService.create(d);

        this.drugs.push(d);
        this.startEdit(d);
    }

    deleteDrug(drug: Drug) {
        this.drugService.delete(drug).subscribe({
            next: data => {
                this.log.log(data);
                // reload data
                this.getDrugs();
            },
            error: error => {
                this.errorMessage =  {description: 'cannot delete drug', error: error.error};
                this.log.log(error);
            }
        });
    }

    getDrugs() {
        this.drugService.get().subscribe(
            drugs => this.drugs = drugs
        );
        this.selectedDrug = null;
    }

}
