
<mat-card appearance="outlined" class="alert" *ngIf="message">
        <mat-card-title class="head">{{title}}</mat-card-title>
<div class="detail">
        <div *ngIf="errorDetailsFirstLine">
                {{errorDetailsFirstLine}}
        </div>
        <ng-container *ngIf="errorDetails">
                <ul>
                        <li *ngFor="let line of errorDetails">{{line}}</li>                
                </ul>
        </ng-container>
</div>
<button (click)="closeMessage()">Dismiss</button>
</mat-card>
