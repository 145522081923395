import { Component } from '@angular/core';
import { AuthService, LoginState } from '../../svc/auth.service';
import { LogService } from '../../svc/log.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorMessage } from '../error/error-message';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {

  form!: FormGroup;
  loading = false;
  submitted = false;
  errorMessage: ErrorMessage;

  loginState : LoginState;
  loginStateSubscription: Subscription;

  constructor(
    private log: LogService, 
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    ) {
      // await changes in the login state - just in case
      this.loginStateSubscription = this.authService.loginStateChange.subscribe((value) => {
        this.loginState = value;
      });
    }
  
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    var result = this.authService.login(this.f.username.value, this.f.password.value)
      .subscribe({
        next: data => {
          this.log.log(data);
          // no need to have these in memory anymore
          this.f.username.setValue(null);
          this.f.password.setValue(null);
          this.loading = false;
        },
        error: error => {
          this.errorMessage =  { description: 'login failed', error: error };
          this.loading = false;
        }
      });
  }

  logout() {
    this.authService.logout().subscribe({
      next: data => {
        this.log.log(data);
      },
      error: error => {
        this.errorMessage =  { description: 'error while logout', error: error };
        this.log.log(error);
      }
    });
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }
}
