<mat-card appearance="outlined" *ngIf="prognosis" style="padding: 5px;">
    Last Refill: {{prognosis.lastRefill}}
</mat-card>     
<mat-card appearance="outlined" style="padding: 5px;">
      <button mat-stroked-button (click)="bill()">Bill {{selectedForBilling}} days</button>
      <button mat-stroked-button (click)="revert()">Revert {{selectedForBilling}} days</button>
      <mat-form-field appearance="outline">
          <mat-label>How many days to bill/revert</mat-label>
          <mat-select [(value)]="selectedForBilling">
            <mat-option>None</mat-option>
            <mat-option value="7">1 Week</mat-option>
            <mat-option value="1">1 Day</mat-option>
            <mat-option value="2">2 Days</mat-option>
            <mat-option value="3">3 Days</mat-option>
            <mat-option value="4">4 Days</mat-option>
            <mat-option value="5">5 Days</mat-option>
            <mat-option value="6">6 Days</mat-option>
          </mat-select>
        </mat-form-field>
</mat-card>

<mat-accordion>
    <mat-expansion-panel *ngFor="let pE of prognosisElements">
        <mat-expansion-panel-header class="right-aligned-header">
            <mat-panel-title>
                <ng-container
                *ngIf="pE.drug.inuse; then inUse; else notInUse">
                </ng-container>

                <ng-template #inUse>
                <div style="font-style:normal">
                    {{pE.drug.name}} {{pE.drug.doseMg}}mg
                </div>
                </ng-template>
                <ng-template #notInUse>
                <div style="font-style:italic">
                    {{pE.drug.name}} {{pE.drug.doseMg}}mg
                </div>
                </ng-template>
            </mat-panel-title>
            <mat-panel-description>
                <div style="float:left; width:120px; text-align: right;">#{{pE.drug.stock}} {{pE.lastsUntil}}</div>
            </mat-panel-description>
        </mat-expansion-panel-header>
            <app-drug-detail [drug]="pE.drug"></app-drug-detail>
    </mat-expansion-panel>
</mat-accordion>
