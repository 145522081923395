import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ErrorMessage } from './error-message';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit, OnChanges {

  @Input()
  message: ErrorMessage;

  title: string;
  errorDetailsFirstLine: string;
  errorDetails: string[];

  @Input()
  autohide: boolean;

  timer: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.message && this.autohide) {
      this.prepareMessages();
      this.timer = setTimeout(
        () => {
          this.message = null;
        }, 10000);
    }
  }
  
  closeMessage(): void {
    clearTimeout(this.timer);
    this.message = null;
    this.title = null;
    this.errorDetailsFirstLine = null;
    this.errorDetails = null;
  }

  prepareMessages(): void {
    if (this.message && this.message.description) {
      this.title = this.message.description;
    }

    if (this.message.error && this.message.error.includes('\n')) {
      // break the stuff down
      this.errorDetails = this.message.error.split('\n');
      // remove the first element and assign it to the first line
      this.errorDetailsFirstLine = this.errorDetails.shift();
      if (this.errorDetails.length === 0) {
        this.errorDetailsFirstLine = null;
      }
    } else {
      this.errorDetailsFirstLine = this.message.error;
    }
  }
}
