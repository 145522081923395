import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { LogService } from "./log.service";

export const AddAuthorizationHeader = (request: HttpRequest<any>, accessToken: string) => {
  return request.clone({
    setHeaders: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export const JwtInterceptor: HttpInterceptorFn = (request, next) => {

  const authService = inject(AuthService);
  const logService = inject(LogService);
  const router = inject(Router);

  // don't intercept for login route
  if (authService.isLoginRoute(request)) {
    logService.log("login route - do not inject anything");
    return next(request);
  }

  const token = authService.getToken(request);

  if (token) {
    request = AddAuthorizationHeader(request, token);
  }
  return next(request).pipe(
    catchError(
      (error) => {
        if (error.status === 401) {
          return authService.refreshAccessToken().pipe(
            switchMap((response) => {
              if (response && response['access']) {
                authService.storeTokens(response['refresh'], response['access']);
                request = AddAuthorizationHeader(request, response['access']);
                return next(request);
              } else {
                // Reauthentication failed, redirect to login page
                logService.log("could not refresh access token - logging out");
                authService.clearTokens();
                router.navigateByUrl("login");
                return of(error);
              }
            })
          );
        // either token is expired or logon request has been denied
        } else if (error.status === 403) {
          logService.log("received 403 in access token refresh - logging out");
          logService.log(error);
          if (!authService.isLogoutRoute(request)) {
            logService.log("we are not on logout path - need to redirect");
            authService.clearTokens();
            router.navigateByUrl("login");
            return of(error);
          } else {
            logService.log("we are on logout path - no need to redirect");
            throw error;
          }
        } else {
          // Re-throw the error to propagate it further
          throw error;
        }
      }
    )
  );
}
