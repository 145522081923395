import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Drug } from '../../../model/drug';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DrugService } from '../../../svc/drug.service';
import { StockRefillComponent } from '../../stock-refill/stock-refill.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-drug-detail',
    templateUrl: './drug-detail.component.html',
    styleUrls: ['./drug-detail.component.css',]
})
export class DrugDetailComponent implements OnInit, OnChanges {

    drugForm: UntypedFormGroup;
    @Input() drug: Drug;
    @Input() editable: boolean;

    constructor(private formBuilder: UntypedFormBuilder, private drugService: DrugService, public dialog: MatDialog) { }

    ngOnInit() {
        if (!this.drugForm) {
            this.createDrugForm();
        }
    }

    ngOnChanges() {
        if (!this.drugForm) {
            this.createDrugForm();
        }
        if (this.drug) {
            this.copyDrugToForm();
        }
    }

    createDrugForm(): void {
        this.drugForm = this.formBuilder.group({
            drugName: ['', Validators.required],
            drugDoseMg: ['', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('[0-9]*')]],
            drugDoseMgPerDay: ['', [Validators.required, Validators.min(0), Validators.max(9999), Validators.pattern('[0-9]*')]],
            drugStock: ['', [Validators.required, Validators.min(0), Validators.max(9999), Validators.pattern('[0-9]*')]],
            drugPrescription: [''],
            drugExternalUrl: ['']
        });
    }

    updateDrug() {
        this.copyDrugFromForm();
        this.drugService.update(this.drug).subscribe(
            d => {
                // do not replace drug since otherwise binding with model is broken
                // so just update values
                this.drug.doseMg = d.doseMg;
                this.drug.doseMgPerDay = d.doseMgPerDay;
                this.drug.stock = d.stock;
                this.drug.name = d.name;
                this.drug.id = d.id;
                this.drug.prescription = d.prescription;
                this.drug.url = d.url;
            }
        );
    }

    cancelUpdateDrug() {
        this.copyDrugToForm();
    }

    refill(drug: Drug) {
        const dialogRef = this.dialog.open(StockRefillComponent, {
            data: { drug: drug, amount: 0 } });

        dialogRef.afterClosed().subscribe(
            result => {
                const newVal = Number(result);
                if (newVal > 0 || newVal < 0) {
                    this.refillExecute(drug, Number(result));
                }
            }
        );
    }

    

    refillExecute(drug: Drug, amount: number): void {
        console.log(amount)  
        this.drugService.refill(drug, amount).subscribe(
            updatedDrug => {
                drug.stock = updatedDrug.stock;
                this.copyDrugToForm();
            }
        );
    }

    copyDrugFromForm() {
        this.drug.name = this.drugForm.controls.drugName.value;
        this.drug.doseMg = this.drugForm.controls.drugDoseMg.value;
        this.drug.doseMgPerDay = this.drugForm.controls.drugDoseMgPerDay.value;
        this.drug.stock = this.drugForm.controls.drugStock.value;
        this.drug.doseMg = this.drugForm.controls.drugDoseMg.value;
        this.drug.prescription = this.drugForm.controls.drugPrescription.value;
        this.drug.url = this.drugForm.controls.drugExternalUrl.value;
    }

    copyDrugToForm() {
        this.drugForm.controls.drugName.setValue(this.drug.name);
        this.drugForm.controls.drugDoseMg.setValue(this.drug.doseMg);
        this.drugForm.controls.drugDoseMgPerDay.setValue(this.drug.doseMgPerDay);
        this.drugForm.controls.drugStock.setValue(this.drug.stock);
        this.drugForm.controls.drugPrescription.setValue(this.drug.prescription);
        this.drugForm.controls.drugExternalUrl.setValue(this.drug.url);
    }
}
