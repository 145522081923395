import { Component } from '@angular/core';
import { DrugService } from 'src/app/svc/drug.service';
import { LogService } from 'src/app/svc/log.service';
import { Prognosis } from '../../model/prognosis';
import { AuthService } from '../../svc/auth.service';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatTableDataSource, MatTableModule } from '@angular/material/table' 
import { PrognosisElement } from '../../model/prognosiselement';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { Drug } from '../../model/drug';

@Component({
  selector: 'app-prognosis',
  templateUrl: './prognosis.component.html',
  styleUrl: './prognosis.component.css',
})
export class PrognosisComponent {

  prognosis: Prognosis;
  prognosisElements: PrognosisElement[];
  selectedForBilling = "7";

  constructor(private drugService: DrugService, private log: LogService) { }

  ngOnInit(): void {
    
    this.drugService.prognosis().subscribe(
      prognosis => 
      {
        this.prognosis = prognosis;
        this.prognosisElements = prognosis.elements;
      }
    );
  }

  bill(): void {
    this.callBillRevert(Number(-this.selectedForBilling));
  }

  revert(): void {
    this.callBillRevert(Number(this.selectedForBilling));
  }

  callBillRevert(amount: number): void {

    this.drugService.billOrRevert(amount).subscribe(
      prognosis => 
      {
        this.prognosis = prognosis;
        this.prognosisElements = prognosis.elements;
      }
    );
  }

}
