import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DrugsComponent } from '../ui/drugs/drugs.component';
import { ConfigComponent } from '../ui/config/config.component';
import { PrognosisComponent } from '../ui/prognosis/prognosis.component';
import { LoginComponent } from '../ui/login/login.component';
import { mmGuardGuard } from '../mm-guard.guard';

const routes: Routes = [
    { path: 'drugs', component: DrugsComponent, canActivate: [mmGuardGuard] },
    { path: 'config', component: ConfigComponent, canActivate: [mmGuardGuard] },
    { path: 'prognosis', component: PrognosisComponent, canActivate: [mmGuardGuard] },
    { path: 'login', component: LoginComponent },
    { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class MedimanagerRoutingModule { }
