export const mmapi = {
    API: '/api/api',
    DRUG_GET: '/api/drug/get',
    DRUG_CREATE: '/api/drug/create',
    DRUG_UPDATE: '/api/drug/update',
    DRUG_DELETE: '/api/drug/delete',
    DRUG_PROGNOSIS: '/api/drug/prognosis',
    DRUG_CHARGE: '/api/drug/charge',
    CONFIG_GET: '/api/config/get',
    CONFIG_CREATE: '/api/config/create',
    CONFIG_UPDATE: '/api/config/update',
    CONFIG_DELETE: '/api/config/delete',
    LOGIN: '/api/login/login',
    LOGOUT: '/api/login/logout',
    REFRESH: '/api/token/refresh',
};
