

    <div style="margin-bottom: 10px;">
        <mat-label>In Use</mat-label>
        <input [disabled]="!editable" matInput type="checkbox" [(ngModel)]="drug.inuse" [checked]="drug.inuse">
    </div>

    <form *ngIf="drug" [formGroup]="drugForm" (ngSubmit)="updateDrug()">
        <mat-form-field appearance="outline">
            <mat-label>Name:</mat-label>
            <input [readonly]="!editable" matInput type="text" formControlName="drugName"  style="width: 100%;">
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Dose (mg)</mat-label>
            <input [readonly]="!editable" matInput type="number" formControlName="drugDoseMg">
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Dose per Day (mg)</mat-label>
            <input [readonly]="!editable" matInput type="number" formControlName="drugDoseMgPerDay">
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Stock</mat-label>
            <input [readonly]="!editable" matInput type="number" formControlName="drugStock">
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>Prescription Description</mat-label>
            <input [readonly]="!editable" matInput type="text" formControlName="drugPrescription">
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>External Description</mat-label>
            <input [readonly]="!editable" matInput type="text" formControlName="drugExternalUrl">
        </mat-form-field>

        <button *ngIf="editable" mat-stroked-button (click)="refill(drug)">
            <mat-icon svgIcon="refill"></mat-icon><span class="button-text">Refill</span>
        </button>
        <button *ngIf="editable" mat-stroked-button [disabled]="!drugForm.valid">
            <mat-icon svgIcon="plus"></mat-icon><span class="button-text">Apply</span>
        </button>
        <button *ngIf="editable" mat-stroked-button (click)="cancelUpdateDrug()">
            <mat-icon svgIcon="undo"></mat-icon><span class="button-text">Undo</span>
        </button>

    </form>
